import React from 'react';
import LayoutLight from '../components/layoutLight';
import SEO from '../components/seo';
import Contact from '../containers/contact';

type ContactPageProps = {};

const ContactPage: React.FunctionComponent<ContactPageProps> = () => {
  return (
    <LayoutLight>
      <SEO
        title="Contacter la Galerie Sifrein"
        description="Formulaire de contact de la galerie Sifrein Paris"
      />

      <Contact />
    </LayoutLight>
  );
};

export default ContactPage;
